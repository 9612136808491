export const tagArr = ["new","fix","strong","heavy"];
export function tag2label(tag){
    let out = "";
    switch (tag) {
        case tagArr[0]:
            out = "新增"
            break;
        case tagArr[1]:
            out = "修复"
            break
        case tagArr[2]:
            out = "优化";
            break;
        case tagArr[3]:
            out = "重要"
            break;
        default:
            out = "未知类型"
            break
    }
    return out
}