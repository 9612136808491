<template>
  <div>
    <el-card>
      <el-form inline>
        <el-form-item label="版本号">
          <el-input v-model="form.version"></el-input>
        </el-form-item>
        <el-form-item label="时间">
          <el-input v-model="form.time"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="addContentItem">添加内容</el-button>
          <el-button @click="formSubmit" type="primary">保存提交</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="formContentList">
        <el-table-column label="标签" width="80">
          <template v-slot="s">{{tag2label(s.row.tag)}}</template>
        </el-table-column>
        <el-table-column label="内容">
          <template v-slot="s"><div v-html="s.row.content"></div></template>
        </el-table-column>
        <el-table-column label="操作" width="160">
          <template v-slot="s">
            <el-button @click="contentItemEdit(s.$index)" type="primary">编辑</el-button>
            <el-button @click="contentItemDel(s.$index)" type="danger">移除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog title="编辑内容项" :visible.sync="contentItemEditBox" destroy-on-close>
      <div>
        <el-form label-position="top">
          <el-form-item label="内容项类型">
            <el-radio-group v-model="contentItemForm.tag">
              <el-radio v-for="item in tagArr" :key="item" :label="item">{{tag2label(item)}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="内容">
            <y_editor v-model="contentItemForm.content"></y_editor>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="contentItemEditBox = false">取 消</el-button>
        <el-button type="primary" @click="contentItemEditSubmit">提交</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {tagArr,tag2label} from "@/view/shop/goods/upgradeLog/tag2label";
import y_editor from "@/components/y-form/y-editor/y_editor";
export default {
  name: "edit",
  components: {y_editor},
  data(){
    return{
      form:{
        goods_id:0,
        version:"",
        time:"",
        content:"",
      },
      formContentList:[],
      contentItemEditBox:false,
      contentItemForm:{
        tag:"new",
        content:"",
      }
    }
  },
  computed:{
    tagArr(){
      return tagArr;
    }
  },
  mounted() {
    if (this.$route.query.id){
      this.load();
    }
  },
  methods:{
    load(){
      this.$u.api.shop.goods.upgradeLogOne({id:parseInt(this.$route.query.id)}).then(res=>{
        this.formContentList = res.content ? JSON.parse(res.content) : [];
        this.form = res;
      })
    },
    formSubmit(){
      this.form.goods_id = parseInt(this.$route.query.goods_id);
      this.form.content = JSON.stringify(this.formContentList);
      this.$u.api.shop.goods.upgradeLogEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.$router.back();
      })
    },
    tag2label(tag){
      return tag2label(tag)
    },
    addContentItem(){
      this.contentItemEditBox = true;
    },
    contentItemEdit(index){
      let item = this.formContentList[index];
      this.contentItemForm = {
        index:index,
        ...item
      }
      this.contentItemEditBox = true;
    },
    contentItemDel(index){
      this.formContentList.splice(index,1);
    },
    contentItemEditSubmit(){
      if (typeof this.contentItemForm.index !== "undefined"){
        const key = this.contentItemForm.index;
        delete this.contentItemForm.index;
        this.formContentList.splice(key,1,this.contentItemForm);
        this.$forceUpdate();
      }else {
        this.formContentList.push(this.contentItemForm);
      }
      this.contentItemForm = {
        tag:"new",
        content:"",
      }
      this.contentItemEditBox = false;
    }
  }
}
</script>

<style scoped>

</style>